:root {
    --primary-color: #C2A07C;
    --secondary-color: #EBEAE6;
    --btn-color: #604433;
    --btn-color-hover: #422F21;
    --text-color: #25211D;
    --dark-bkg: #18191a;
    --dark-card: #242526;
}

h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
}
h1, h2 {
    font-weight: 900 !important;
}

h2 .small {
    font-size: .8em;
}
h2 .large {
    font-size: 1.3em;
    font-weight: 900 !important;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 18px !important;
}

.font-weight-bold {
    font-weight: 700;
}

.dark {
    background-color: var(--dark-bkg);
}
.dark .secondary {
    background-color: var(--dark-card);  
    color: var(--secondary-color); 
}

.dark .heading {
    color: var(--primary-color);
}

.card-bkg {
    overflow: hidden;
    height: 100%;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}
.primary {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}
.secondary {
    background-color: var(--secondary-color);
}

.br-25 {
    border-radius: 25px;
}

/* DARK MODE PILL BOX STYLES */
.checkbox {
    opacity: 0;
    position: absolute;
  }
  
.checkbox-label {
    background-color: #111;
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fa-moon {color: #fff;}

.fa-sun {color: #fff;}

.checkbox-label .circle {
    background-color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .circle {
    transform: translateX(24px);
}
/* DARK MODE PILL BOX STYLES END */
a {
    text-decoration: none !important;
}
a .skill:hover {
    background-color: var(--btn-color-hover);
}
.skill {
    padding: 10px 15px;
    border-radius: 25px;
    background-color: var(--btn-color);
    text-align: center;
    color: var(--secondary-color);
    font-weight: 500;
    margin-bottom: 10px;
    margin-right: 10px;
}

.tool {
    padding: 10px 15px;
    border-radius: 25px;
    background-color: var(--btn-color);
    text-align: center;
    color: var(--secondary-color);
    font-weight: 500;
    margin-right: 10px;
    width: fit-content;
}
.tool-desc {
    margin-bottom: 15px;
}
.italic {
    font-style: italic !important;
}
.heading {
    font-size: 1.2em;
    color: var(--btn-color-hover);
    font-weight: 700;
}
.subhead {
    font-weight: 700;
}
@media screen and (max-width: 1199px) {
    .mb-30-md-down {
        margin-bottom: 30px;
    }
    .profile-picture {
        min-height: 600px;
    }
}
.profile-picture {
    background-image: url(./img/profile-picture.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}